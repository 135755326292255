(function($) {
  $(document).ready(function() {
    var $dataDisplayMenu = $( '#data-display-menu' );
    var $dataDisplayToggle = $( '.data-display-menu-toggle' );

    function toggleDataDisplayMenu() {
      $dataDisplayMenu.animate({width:'toggle'},300);
    }

    $dataDisplayToggle.on("click", toggleDataDisplayMenu)
  })
})(jQuery);