(function ($) {
	// Don't execute any of the below code until the document
	// Has fully loaded
	$(document).ready(function () {

		// add map container class
		$('.main-container').addClass('main-container-map-content');

		// initialize variables
		let popup = '';
		let config = {};
		const $zoomBtn = $('.btn-zoom-to');
		const $jumpToBtn = $('.btn-data-display');
		let $layerControls;
		let $basemapControls;
		let systemLayerConfig = [];

		// Freeze the Drupal settings so that they cannot be overwritten
		Object.freeze(Drupal.settings);

		/**
		 * This function is used to integrate the Drupal content type
		 * settings implemented on the specific page (aka node)
		 * These settings are exposed in JavaScript by adding a property
		 * for each setting to the Drupal.settings object via PHP
		 * To view how this is done,
		 * please view the ./node--map_interface.tpl.php file
		 */
		(function configPage() {
			config = {
				mapSidebar: Drupal.settings.mapSidebar,
				mapbox: Drupal.settings.mapbox,
				drupalUserid: Drupal.settings.currentUser
			}

			config.mapbox.center = [parseFloat(config.mapbox.center[0]), parseFloat(config.mapbox.center[1])];
			config.mapbox.selectedLocation = '1';
			config.mapbox.selectedZoomLocation = '0';
		})();

		/**
		 * This function is used to retrieve the permissions
		 * for each individual United systems layer for the current user
		 * returns a promise
		 */
		function getSystemLayerPermissions() {
			return $.ajax({
				url: './sites/all/themes/lrebootstrap/scripts/php/view/',
				data: {
					table: 'spatial.system_layers_to_drupal_user',
					fields: ['*'],
					drupal_user_switch: true,
					drupal_userid: config.drupalUserid,
				}
			})
		}

		/**
		 * This function is used to set the visibility for a system layer based on
		 * if the user has access to the layer/if the visibility checkbox is checked/unchecked
		 * @param {*} layerName
		 */
		function setActiveSystemLayer(layerName) {
			return systemLayerConfig.map((layer) => {
				if (layer.name === layerName) {
					return Object.assign(layer, { name: layerName, visible: true })
				}
				return layer;
			});
		}

		/**
		 * This function is used to add a map data source
		 * Checks if the source already exists.
		 * If it exists, do nothing
		 * else, add the data source
		 * @param {*} source
		 * @param {*} data
		 */
		function addMapSource(source, data) {
			if (typeof map.getSource(source) === "undefined") {
				map.addSource(source, {
					type: 'geojson',
					data: data
				});
			};
		}

		/**
		 * TODO Write function to remove existing sources and layers
		 */
		function tidyMap() {

		}

		/**
		 * This function is used to add all of the public locations to the map
		 * i.e. public diversions, stream gages, river calls, and
		 * United's private locations that they have elected to make visible
		 * to the public
		 * This function handles adding both the data sources as well as the
		 * actual map layers
		 */
		function addPublicLocations() {

			/**
			 * Add the data source for the public locations
			 * For more information on adding a data source to Mapbox,
			 * please reference the Mapbox addSource documentation at
			 * https://docs.mapbox.com/mapbox-gl-js/api/#map#addsource
			 */
			map.addSource('public_locations', {
				type: 'geojson',
				data: '/sites/all/themes/lrebootstrap/scripts/php/spatial/index.php?geotable=spatial.locations_public_table&geomfield=geom&srid=4326&fields=location_ndx,location_desc,meas_category,most_recent_timestamp,most_recent_value,display_type_ndx',
			});

			/**
			 * Add the data source for the rivercalls
			 * For more information on adding a data source to Mapbox,
			 * please reference the Mapbox addSource documentation at
			 * https://docs.mapbox.com/mapbox-gl-js/api/#map#addsource
			 */
			map.addSource('rivercalls', {
				type: 'geojson',
				data: '/sites/all/themes/lrebootstrap/scripts/php/spatial/index.php?geotable=spatial.rivercalls&geomfield=geom&srid=4326&fields=location_structure_name,date_time_set,water_source_name,location_wdid,priority_admin_no,priority_date,div,wd',
			});

			/**
			 * Add the public stream gage layer to the map
			 * For more information on how this layer is added to the map, please reference
			 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
			 */
			map.addLayer({
				id: 'public_locations_layer_stream',
				type: 'circle',
				source: 'public_locations',
				paint: {
					"circle-radius": 6,
					"circle-color": "#FFEB3B",
					"circle-stroke-width": 2,
					"circle-stroke-color": "#222"
				},
				filter: ["==", "meas_category", "Stream"],
			})

			/**
			 * Add pulic locations that United would like styled to look their private locations
			 * For more information on how this layer is added to the map, please reference
			 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
			 */
			map.addLayer({
				id: 'public_locations_layer_united_public',
				type: 'circle',
				source: 'public_locations',
				paint: {
					"circle-radius": 6,
					"circle-color": "#0d47a1",
					"circle-stroke-width": 2,
					"circle-stroke-color": "#FFF"
				},
				filter: ["==", "meas_category", "United Public"],
			})

			/**
			 * Add diversions to the map
			 * For more information on how this layer is added to the map, please reference
			 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
			 */
			map.addLayer({
				id: 'public_locations_layer_div',
				type: 'symbol',
				source: 'public_locations',
				layout: {
					"icon-image": "green-triangle-15",
					"icon-ignore-placement": true
				},
				filter: ["==", "meas_category", "Diversion"],
			})

			/**
			 * Add river calls to the map
			 * For more information on how this layer is added to the map, please reference
			 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
			 */
			map.addLayer({
				id: 'rivercalls_layer',
				type: 'symbol',
				source: 'rivercalls',
				layout: {
					"icon-image": "triangle-red-black-15",
					"icon-ignore-placement": true,
					"icon-rotate": 180
				},
			})
		}

		/**
		 * This function is used to add all of United's private locations to the map
		 * This function handles adding both the data sources as well as the
		 * actual map layers
		 */
		function addPrivateLocations() {

			/**
			 * Add the data source for the United's private locations
			 * For more information on adding a data source to Mapbox,
			 * please reference the Mapbox addSource documentation at
			 * https://docs.mapbox.com/mapbox-gl-js/api/#map#addsource
			 */
			map.addSource('united_locations', {
				type: 'geojson',
				data: `/sites/all/themes/lrebootstrap/scripts/php/spatial/index.php?geotable=spatial.locations_aggregate_group_by_drupal_user&geomfield=geom&srid=4326&fields=location_ndx,location_desc,zoom_label,display_type_ndx,most_recent_timestamp,most_recent_value,pct_full,data_page_link,volume,units_volume,drupal_userid&parameters=drupal_userid=${config.drupalUserid}`,
			})

			/**
			 * Add United's private locations
			 * For more information on how this layer is added to the map, please reference
			 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
			 */
			map.addLayer({
				id: 'united_locations_layer',
				type: 'circle',
				source: 'united_locations',
				paint: {
					"circle-radius": 6,
					"circle-color": "#0d47a1",
					"circle-stroke-width": 2,
					"circle-stroke-color": "#FFF"
				},
				filter: ["==", "drupal_userid", config.drupalUserid],
			})
		}

		function checkSystemLayerPermission(grantedLayers, layerName) {
			const matchedLayer = grantedLayers.filter(layer => layer.system_layer_desc === layerName);
			if (matchedLayer.length > 0) {
				return matchedLayer[0];
			}
			return null;
		}

		/**
		 * This function is used to add all system layers to the map
		 * the system layers are user specific and only layers granted
		 * to that user will be displayed
		 * @param {array} grantedLayers array of layers granted to the active user
		 */
		function addSystemLayers(grantedLayers) {
			const augStations = checkSystemLayerPermission(grantedLayers, 'augmentation_stations');
			const storageFacilities = checkSystemLayerPermission(grantedLayers, 'storage_facilities');
			const farmsProperties = checkSystemLayerPermission(grantedLayers, 'farms_and_properties');
			const rechargeFacilities = checkSystemLayerPermission(grantedLayers, 'recharge_facilities');
			const pipelinesFacilities = checkSystemLayerPermission(grantedLayers, 'pipelines_facilities');

			// Add augmentation stations to map if user has appropriate permissions
			if (augStations) {

				/**
				 * Add augmentation stations layer (line) and hide by default
				 * For more information on how this layer is added to the map, please reference
				 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
				 */

				map.addLayer({
					id: 'augmentation_stations_line_layer',
					type: 'line',
					source: {
						type: 'vector',
						url: 'mapbox://unitedwater.augmentation_stations'
					},
					'source-layer': 'augmentation_stations',
					layout: {
						visibility: 'none'
					},
					paint: {
						'line-color': augStations.fill_color,
						'line-width': 2,
					},
					filter: ['==', ['geometry-type'], 'LineString']
				});

				/**
				 * Add augmentation stations layer (point) and hide by default
				 * For more information on how this layer is added to the map, please reference
				 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
				 */
				map.addLayer({
					id: 'augmentation_stations_point_layer',
					type: 'circle',
					source: {
						type: 'vector',
						url: 'mapbox://unitedwater.augmentation_stations'
					},
					'source-layer': 'augmentation_stations',
					layout: {
						visibility: 'none'
					},
					paint: {
						"circle-radius": 5,
						"circle-color": augStations.fill_color,
						"circle-stroke-width": 1,
						"circle-stroke-color": augStations.stroke_color
					},
					filter: ['==', ['geometry-type'], 'Point']
				});
			}

			// Add storage facilities to map if user has appropriate permissions
			if (storageFacilities) {

				/**
				 * Add storage facilities layer and hide by default
				 * For more information on how this layer is added to the map, please reference
				 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
				 */
				map.addLayer({
					id: 'storage_facilities_layer',
					type: 'fill',
					source: {
						type: 'vector',
						url: 'mapbox://unitedwater.storage_facilities'
					},
					'source-layer': 'storage_facilities',
					layout: {
						visibility: 'none'
					},
					paint: {
						"fill-color": storageFacilities.fill_color,
						"fill-outline-color": storageFacilities.stroke_color
					},
				});
			}

			// Add farms and properties to map if user has appropriate permissions
			if (farmsProperties) {

				/**
				 * Add farms and properties layer and hide by default
				 * For more information on how this layer is added to the map, please reference
				 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
				 */
				map.addLayer({
					id: 'farms_and_properties_layer',
					type: 'fill',
					source: {
						type: 'vector',
						url: 'mapbox://unitedwater.farms_and_properties'
					},
					'source-layer': 'farms_and_properties',
					layout: {
						visibility: 'none'
					},
					paint: {
						"fill-color": farmsProperties.fill_color,
						"fill-outline-color": farmsProperties.stroke_color,
						"fill-opacity": 0.4
					},
				});
			}

			// Add recharge facilities to map if user has appropriate permissions
			if (rechargeFacilities) {

				/**
				 * Add recharge facilities layer and hide by default
				 * For more information on how this layer is added to the map, please reference
				 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
				 */
				map.addLayer({
					id: 'recharge_facilities_layer',
					type: 'fill',
					source: {
						type: 'vector',
						url: 'mapbox://unitedwater.recharge_facilities'
					},
					'source-layer': 'recharge_facilities',
					layout: {
						visibility: 'none'
					},
					paint: {
						"fill-color": rechargeFacilities.fill_color,
						"fill-outline-color": rechargeFacilities.stroke_color,
						"fill-opacity": 0.4
					},
				});
			}

			// Add pipelines facilities to map if user has appropriate permissions
			if (pipelinesFacilities) {

				/**
				 * Add pipeline facilities layer and hide by default
				 * For more information on how this layer is added to the map, please reference
				 * the Mapbox addLayer documentation https://docs.mapbox.com/mapbox-gl-js/api/#map#addlayer
				 */
				map.addLayer({
					id: 'pipelines_facilities_layer',
					type: 'line',
					source: {
						type: 'vector',
						url: 'mapbox://unitedwater.pipelines_facilities'
					},
					'source-layer': 'pipelines_facilities',
					layout: {
						visibility: 'none'
					},
					paint: {
						"line-color": pipelinesFacilities.fill_color,
						"line-width": 3
					},
				});
			}
		}

		/**
		 * This function is used to initialize and configure the map and
		 * is responsible for:
		 * + resizing the map after page load
		 * + requesting the system layers that are granted to the current user
		 * + adding public locations to map
		 * + adding United's private locations to map that are granted to the current user
		 * + adding system layers to map that are granted to the current user
		 * + adding layer controls to the map
		 */
		function configureMap() {

			// resize map to fit container completely after load
			map.resize();

			// after the system layer permissions are retrieved, adjust the system layer visibilities accordingly
			getSystemLayerPermissions().done((response) => {
				const data = JSON.parse(response);
				if (data) {

					// Set the default visiblity for each system layer
					// changed to false on 1.2.19 pre United's request
					// UPDATE THE VISIBLE ATTRIBUTE IF A CHANGE IN THIS FUNCTIONALITY IS DESIRED
					systemLayerConfig = data.map(d => Object.assign(d, { visible: false }));

					addSystemLayers(systemLayerConfig); // add system layers to the map
					addPublicLocations(); // add public layers to the map
					addPrivateLocations(); // add private layers to the map
					addLayerControls(systemLayerConfig);
				} else {
					addPublicLocations(); // add public layers to the map
					addPrivateLocations(); // add private layers to the map
					addLayerControls(systemLayerConfig);
				}
			});
		}

		/**
		 * This function is used to create add the layer toggle controls
		 */
		function addLayerControls(systemLayers) {
			const $controlTopRight = $('.mapboxgl-ctrl-top-right');
			$controlTopRight.attr("id", "map-layers-control");

			const basemapControls = `
				<div id="mapbox-basemap-ctrl" class="mapboxgl-ctrl mapboxgl-ctrl-group text-center">
					<button class="mapboxgl-ctrl-icon" type="button" aria-label="Physical" data-style="" >
						<i class="material-icons">terrain</i>
					</button>
					<button class="mapboxgl-ctrl-icon" type="button" aria-label="Satellite" data-style="" >
						<i class="material-icons">satellite</i>
					</button>
					<button class="mapboxgl-ctrl-icon" type="button" aria-label="Dark" data-style="" >
						<i class="material-icons">wb_cloudy</i>
					</button>
				</div>
			`;

			if (systemLayers.length > 0) {
				const systemLayersControls = `
					<div id="mapbox-system-layers-ctrl" class="mapboxgl-ctrl mapbox-ctrl-collapsed mapboxgl-ctrl-group text-center">
						<button class="mapboxgl-ctrl-icon" type="button" aria-label="Physical" data-style="" >
							<i class="material-icons">layers</i>
						</button>
					</div>
				`;
				$controlTopRight.html(basemapControls + systemLayersControls);
				$basemapControls = $('#mapbox-basemap-ctrl');
				$basemapControls.on('click', toggleBasemap);
				$layerControls = $('#mapbox-system-layers-ctrl');
				$layerControls.on('click', event => toggleLayerControls(event, systemLayers));
				$layerControls.on('click', toggleLayerControlsCheckbox);
			} else {
				$controlTopRight.html(basemapControls);
				$basemapControls = $('#mapbox-basemap-ctrl');
				$basemapControls.on('click', toggleBasemap);
			}

			setBasemapControlStyles();
		};

		/**
		 * This function is used to associate a Mapbox style with each layer control button
		 */
		function setBasemapControlStyles() {
			var $buttons = $basemapControls.find('button');
			$.each($buttons, function (k, v) {
				$(this).attr("data-style", config.mapbox.styles[k]);
			})
		}

		/**
		 * This function is used to set the HTML for the system layers controls
		 * @param {*} systemLayers
		 */
		function setLayerControlsHtml(systemLayers) {
			if (systemLayers.length > 0) {
				let html = `
					<div class="layout-padding text-left">
						<div class="margin-bottom text-left">
							<i class="material-icons vertical-align-middle">layers</i>System Layers
							<button class="bg-blue text-white btn margin-left">close</button>
						</div>`;

				systemLayers.forEach((layer) => {
					let checkbox;
					if (layer.visible) checkbox = 'check_box';
					if (!layer.visible) checkbox = 'check_box_outline_blank';
					html += `
						<div>
							<i class="material-icons vertical-align-middle text-blue" data-layer="${layer.system_layer_desc}">${checkbox}</i>
							<div class="system-layer-legend" style="background-color:${layer.fill_color}; border: 1px solid ${layer.stroke_color}"></div>
							${layer.system_layer_display}
						</div>
					`;
				});
				html += `</div>`;
				return html;
			}
		}

		/**
		 * This function is used to update the checkbox associated with the layer
		 * the user has selected to enable/disable from the layer controls
		 * This function also updates the visibility of the associated map layer
		 * @param {object} event JavaScript event object
		 */
		function toggleLayerControlsCheckbox(event) {
			const $target = event.target;
			const activeLayerName = $target.getAttribute('data-layer');

			// the current visibility of the active layer
			const visibility = $target.textContent === 'check_box' ? 'visible' : 'none';

			// the new visibility of the active layer aka the opposite of the current value
			const toggledVisibility = visibility === 'visible' ? 'none' : 'visible';

			// toggle the checkbox to checked/unchecked
			if (visibility === 'visible') {
				$target.textContent = 'check_box_outline_blank';
			} else if (visibility === 'none') {
				$target.textContent = 'check_box';
			}

			// toggle the visible property for the active layer
			systemLayerConfig = systemLayerConfig.map((layer) => {
				let obj = Object.assign(layer);
				if (obj.system_layer_desc.includes(activeLayerName)) {
					obj.visible = visibility === 'none' ? true : false;
				};
				return obj;
			});

			// update the actual layer visibility on the map
			// because there are two augmentation station layers and only one legend
			// item associated with augmentation stations,
			// this check is necessary to ensure that both aug stations layers are
			// toggled on/off
			if (activeLayerName.includes('augmentation_stations')) {
				map.setLayoutProperty(`${activeLayerName}_point_layer`, 'visibility', toggledVisibility);
				map.setLayoutProperty(`${activeLayerName}_line_layer`, 'visibility', toggledVisibility);
			} else {
				map.setLayoutProperty(`${activeLayerName}_layer`, 'visibility', toggledVisibility);
			}
		}

		/**
		 * This function is used to show/hide the System Layer Controls box
		 * @param {*} event
		 * @param {*} systemLayers
		 */
		function toggleLayerControls(event, systemLayers) {
			const $target = event.target;

			if ($target.textContent === 'layers' || $target.textContent === 'close') {
				if ($layerControls.hasClass('mapbox-ctrl-collapsed')) {
					$layerControls.removeClass('mapbox-ctrl-collapsed');
					$layerControls.addClass('mapbox-ctrl-expanded');

					const html = setLayerControlsHtml(systemLayers);
					$layerControls.html(html);
				} else {
					$layerControls.removeClass('mapbox-ctrl-expanded');
					$layerControls.addClass('mapbox-ctrl-collapsed');

					$layerControls.html(`
						<button class="mapboxgl-ctrl-icon" type="button" aria-label="Physical" data-style="" >
							<i class="material-icons">layers</i>
						</button>
					`);
				}
			}
		}

		/**
		 * This function is used to toggle the active basemap when a user clicks on the layer control button
		 * @param  {object} e JavaScript event object
		 */
		function toggleBasemap(e) {
			const target = $(e.target).parent();
			map.setStyle(target.attr('data-style'));
			map.on('style.load', configureMap);
		}

		/**
		 * This function is used to generate Mapbox popup
		 * The popup generated depends on the layer the user has selected
		 * @param {*} feature
		 * @param {*} e
		 */
		function createPopup(feature, e) {

			if (feature.layer.source == 'rivercalls') {
				popup = new mapboxgl.Popup({ closeOnClick: false })
					.setLngLat(feature.geometry.coordinates)
					.setHTML(`
						<div class="map-popup-header-wrapper col-xs-12">
							<i class="material-icons md-24 text-white pull-left">invert_colors</i>
							<div class="map-popup-header text-bold pull-left">
								${feature.properties.location_structure_name}
							</div>
							<i class="material-icons md-24 text-white pull-right close-map-popup">close</i>
						</div>
						<div class="clearfix"></div>
						<div class="map-popup-subheader">
							Date Time Set: ${feature.properties.date_time_set}
						</div>
						<div class="map-popup-subheader">
							Water Source: ${feature.properties.water_source_name}&nbsp&nbsp
						</div>
						<div class="map-popup-subheader">
							Location WDID: ${feature.properties.location_wdid}
						</div>
						<div class="map-popup-subheader">
							Priority Admin No: ${feature.properties.priority_admin_no}
						</div>
						<div class="map-popup-subheader">
							Priority Date: ${feature.properties.priority_date}
						</div>
						<div class="map-popup-subheader">
							Division: ${feature.properties.div}
						</div>`)
					.addTo(map);
			} else if (feature.layer.source === 'public_locations') {
				const mostRecentValue = JSON.parse(feature.properties.most_recent_value).flat();
				const mostRecentHTML = mostRecentValue.map((d) => {
					return `
						<div class="map-popup-subheader">${d}</div>
						<div class="text-center margin-bottom">
							<a id="view-chart-btn" href="./public_stations?location=${feature.properties.location_ndx}" class="btn btn-primary">View Chart</a>
						</div>
					`;
				}).join('');

				popup = new mapboxgl.Popup({ closeOnClick: false })
					.setLngLat(feature.geometry.coordinates)
					.setHTML(`
						<div class="map-popup-header-wrapper col-xs-12">
							<i class="material-icons md-24 text-white pull-left">location_on</i>
							<div class="map-popup-header pull-left">
								${feature.properties.location_desc}
							</div>
							<i class="material-icons md-24 text-white pull-right close-map-popup">close</i>
						</div>
						<div class="clearfix"></div><div class="map-popup-subheader">
							Last Update: ${feature.properties.most_recent_timestamp}
						</div>
						${mostRecentHTML}
						<div class="clearfix"></div>
					`)
					.addTo(map);
			} else if (feature.layer.source === 'united_locations') {
				const mostRecentValue = JSON.parse(feature.properties.most_recent_value).flat();
				let mostRecentHTML = mostRecentValue.map((d) => {
					return `
						<div class="map-popup-subheader">${d}</div>
					`;
				}).join("");

				if (feature.properties.pct_full !== "") {
					mostRecentHTML += `
						<div class="map-popup-subheader">
							${feature.properties.location_desc} Percent Full: ${feature.properties.pct_full}%
						</div>
					`;
				}

				if (feature.properties.volume !== "") {
					mostRecentHTML += `
						<div class="map-popup-subheader">
							${feature.properties.location_desc} Volume: ${feature.properties.volume} ${feature.properties.units_volume}
						</div>
					`;
				}

				popup = new mapboxgl.Popup({ closeOnClick: false })
					.setLngLat(feature.geometry.coordinates)
					.setHTML(`
						<div class="map-popup-header-wrapper col-xs-12">
							<i class="material-icons md-24 text-white pull-left">location_on</i>
							<div class="map-popup-header pull-left">
								${feature.properties.location_desc}
							</div>
							<i class="material-icons md-24 text-white pull-right close-map-popup">close</i>
						</div>
						<div class="clearfix"></div>
						<div class="map-popup-subheader">
							Last Update: ${feature.properties.most_recent_timestamp}
						</div>
						${mostRecentHTML}
						<div class="clearfix"></div>
						<div class="col-xs-12 text-center margin-bottom">
							<a href="${feature.properties.data_page_link}">
								<button class="btn btn-primary">View Facility Page</button>
							</a>
						</div>
					`)
					.addTo(map);
			} else {
				popup = new mapboxgl.Popup({ closeOnClick: false })
					.setLngLat(e.lngLat)
					.setHTML(`
							<div class="map-popup-subheader col-xs-12">
								<i class="material-icons md-24 pull-left">location_on</i>
								<div class="pull-left">${feature.properties.name}</div>
								<i class="material-icons md-24 pull-right close-map-popup">close</i>
							</div>
							<div class="clearfix"></div>
						`)
					.addTo(map);
			}

			const $closePopupBtn = $('.close-map-popup');
			$closePopupBtn.on("click", (e) => {
				e.preventDefault();
				// check if instance of mapboxgl.Popup exists, if so remove all popups
				if (popup instanceof mapboxgl.Popup) {
					popup.remove();
				}
			});
		}

		/**
		 * This function is used to handle to zooming to the location
		 * on the map that the user selects from the Zoom to Facility
		 * dropdown menu
		 */
		function zoomTo() {
			const activeLocationNdx = zoomToDropdown.val();
			const activeLocationObj = zoomToDropdown.data
				.filter(d => d.location_group_ndx == activeLocationNdx)[0];

			map.flyTo({
				center: [activeLocationObj.centroid_lon, activeLocationObj.centroid_lat],
				zoom: activeLocationObj.zoom_level,
			})
		}

		// fire zoomTo function on zoom to facility button click
		$zoomBtn.on('click', zoomTo);

		/**
		 * This function is used to handle the logic associated with
		 * jumping to a user's selected data display
		 */
		function jumpToDataDisplay() {
			const activeDataDisplayNdx = dataDisplayDropdown.val();
			const activeDataDisplayObj = dataDisplayDropdown.data
				.filter(d => d.location_group_ndx == activeDataDisplayNdx)[0];

			window.location.href = activeDataDisplayObj.data_page_link;
		}

		// fire jumpToDataDisplay function on button click
		$jumpToBtn.on("click", jumpToDataDisplay);

		/**
		 * This utility function is used as a shorthand for requesting
		 * data for a dropdown menu from the database
		 * @param {object} options
		 */
		function populateDropdown(options) {
			return $.ajax({
				url: './sites/all/themes/lrebootstrap/scripts/php/view/',
				data: {
					table: options.table,
					fields: options.fields,
					drupal_user_switch: true,
					drupal_userid: config.drupalUserid,
					sort: options.sort
				}
			})
		}

		/**
		 * Create the zoomToDropdown
		 * The functionality is handled through Mercury Forms
		 */
		const zoomToDropdown = new Dropdown({
			container: "#map-dropdown1",
			elementExists: true,
			optionGroups: true,
			optionGroupsField: "menu_group",
			onChange: function () { // define behavior when selected value changes
				config.mapbox.selectedZoomLocation = zoomToDropdown.val();
			}
		});

		/**
		 * Create the dataDisplayDropdown
		 * The functionality is handled through Mercury Forms
		 */
		const dataDisplayDropdown = new Dropdown({
			container: "#map-dropdown2",
			elementExists: true,
			optionGroups: true,
			optionGroupsField: "menu_group",
			onChange: function () { // define behavior when selected value changes
				config.mapbox.selectedDataDisplay = dataDisplayDropdown.val();
			}
		});

		/**
		 * create a reference to the promise returned when requesting
		 * the zoomToDropdown data source
		 */
		const zoomToDropdownPromise = populateDropdown({
			table: config.mapSidebar.dropdownSource1,
			fields: config.mapSidebar.dropdownFields1.split(","),
			sort: 'menu_group_display_order ASC, 2 ASC'
		});

		/**
		 * create a reference to the promise returned when requesting
		 * the dataDisplayDropdown data source
		 */
		const dataDisplayDropdownPromise = populateDropdown({
			table: config.mapSidebar.dropdownSource2,
			fields: config.mapSidebar.dropdownFields2.split(","),
			sort: 'menu_group_display_order ASC, 2 ASC'
		});

		/**
		 * After the zoomToDropdown data is returned from the database,
		 * set the data and build the dropdown
		 */
		$.when(zoomToDropdownPromise).done((data) => {
			const response = JSON.parse(data);
			if (response) {
				zoomToDropdown.data = response;
				zoomToDropdown.buildDropdown();
			}
		});

		/**
		 * After the dataDisplayDropdown data is returned from the database,
		 * set the data and build the dropdown
		 */
		$.when(dataDisplayDropdownPromise).done((data) => {
			const response = JSON.parse(data);
			if (response) {
				dataDisplayDropdown.data = response;
				dataDisplayDropdown.buildDropdown();
			}
		})

		/**
		 * Integrate the Drupal content type settings implemented on the specific page (aka node)
		 * that are specific to the map
		 * These settings are exposed in JavaScript by adding a property
		 * for each setting to the Drupal.settings object via PHP
		 * To view how this is done,
		 * please view the ./node--map_interface.tpl.php file
		 */
		let mapSettings = {
			styles: config.mapbox.mapStyle,
			center: config.mapbox.mapCenter,
			zoomLevel: config.mapbox.mapZoomLevel,
			selectedLocation: "1",
			selectedZoomLocation: "0"
		}

		// Mapbox API access token, each Mapbox account has their own tokens
		// login to the United Mapbox account to view/create tokens
		mapboxgl.accessToken = 'pk.eyJ1IjoidW5pdGVkd2F0ZXIiLCJhIjoiY2s1Z3VkM2t6MGJ5ZTNsb2N0d3U2OWdoaCJ9.anoX73YvOM12B1QUFLChFA';

		// create the Mapbox map object,
		// passing in options from content type
		const map = new mapboxgl.Map({
			container: 'map',
			style: config.mapbox.styles[0],
			zoom: parseInt(config.mapbox.zoomLevel),
			center: [config.mapbox.center[1], config.mapbox.center[0]]
		});

		// Add zoom and rotation controls to the map
		map.addControl(new mapboxgl.NavigationControl(), 'top-left');

		// add layers after map load
		map.on('load', function () {
			configureMap();
		});

		// define what should happen if a user clicks on the map
		map.on('click', function (e) {
			// check if instance of mapboxgl.Popup exists, if so remove all popups
			if (popup instanceof mapboxgl.Popup) {
				popup.remove();
			}
			const systemLayers = systemLayerConfig
				.filter(l => l.system_layer_desc !== 'augmentation_stations')
				.map(l => `${l.system_layer_desc}_layer`);

			if (systemLayerConfig.map(l => l.system_layer_desc).includes('augmentation_stations')) {
				systemLayers.push('augmentation_stations_line_layer');
				systemLayers.push('augmentation_stations_point_layer');
			}

			// Map layers to check if the user has clicked on
			const layersToQuery = [
				'united_locations_layer',
				'public_locations_layer_stream',
				'public_locations_layer_united_public',
				'public_locations_layer_div',
				'rivercalls_layer',
				...systemLayers,
			];

			const features = map.queryRenderedFeatures(e.point, { layers: layersToQuery });
			const feature = features[0];
			if (!feature) return null;

			// update the config object
			config.mapbox.selectedLocation = feature.properties;
			mapSettings.dataDisplayType = feature.properties.display_type_ndx;

			createPopup(feature, e);
			const currentZoomLevel = map.getZoom();

			/**
			 * This logic uses the zoom level to determine how the feature
			 * should be centered and based on the current map zoom level
			 */
			if (currentZoomLevel < 10) {
				map.flyTo({
					center: [e.lngLat.lng, parseFloat(e.lngLat.lat) - 0.20]
				})
			} else if (currentZoomLevel >= 10 && currentZoomLevel < 13) {
				map.flyTo({
					center: [e.lngLat.lng, parseFloat(e.lngLat.lat) - 0.02]
				})
			} else if (currentZoomLevel >= 13 && currentZoomLevel < 15) {
				map.flyTo({
					center: [e.lngLat.lng, parseFloat(e.lngLat.lat) - 0.001]
				})
			} else if (currentZoomLevel >= 15) {
				map.flyTo({
					center: [e.lngLat.lng, parseFloat(e.lngLat.lat)]
				})
			}
		}); // map.on('click')

		// support for on hover behavior. in this case changing the mouse style
		map.on('mousemove', function (e) {
			const layersToQuery = ['united_locations_layer', 'public_locations_layer_stream', 'public_locations_layer_div', 'rivercalls_layer', 'public_locations_layer_united_public'];

			const features = map.queryRenderedFeatures(e.point, { layers: layersToQuery });
			if (!features.length) {
				map.getCanvas().style.cursor = features.length ? 'cursor' : '';
			}
			map.getCanvas().style.cursor = features.length ? 'pointer' : '';
		});
	});

})(jQuery);
